import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Provider } from 'react-redux'
import {
    compose,
    applyMiddleware,
    createStore as reduxCreateStore,
} from 'redux'
import rootReducer from '.'
import { cacheEnhancer } from 'redux-cache'

const createStore = () =>
    reduxCreateStore(
        rootReducer,
        compose(
            applyMiddleware(),
            cacheEnhancer()
        )
    )
export default ({ element }) => (
    <ParallaxProvider>
        <Provider store={createStore()}>{element}</Provider>
    </ParallaxProvider>
)
