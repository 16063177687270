import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'
const initialState = {
    [DEFAULT_KEY]: null,
    IsHungarian: true,
    lang_switch: "HU"
}

const TOOGLE_DARKMODE = 'TOGGLE_DARKMODE'
const SELECT_LANG = 'SELECT_LANG'
export const toggleDarkMode = IsHungarian => ({
    type: TOOGLE_DARKMODE,
    IsHungarian,
    DEFAULT_KEY,
})
export const selectLang = langSwitch => ({
    type: SELECT_LANG,
    langSwitch
})

export default (state = initialState, action) => {
    switch (action.type) {
        case TOOGLE_DARKMODE:
            return {
                ...state,
                [DEFAULT_KEY]: generateCacheTTL(),
                IsHungarian: action.IsHungarian,
            }
        case SELECT_LANG:
            return {
                lang_switch: action.langSwitch
            }

        default:
            return state
    }
}
